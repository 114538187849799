var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',{staticClass:"primary mt-4 mx-0 pa-6 white--text",attrs:{"cols":"12"}},[_c('v-row',[_c('div',{staticClass:"text-uppercase font-weight-bold"},[_vm._v("loans")])]),_c('v-row',{},[_c('div',{},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s ")])])],1),_c('div',{staticClass:"pa-2 mt-5"},[_c('div',[_c('v-row',_vm._l((_vm.overview),function(item){return _c('v-col',{key:item.title},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"font-weight-light text-capitalize"},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('v-card-text',[_c('v-list',{staticClass:"text-capitalize",attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"large":"","color":item.color}},[_vm._v(" mdi-cash ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" 389000 ")]),_c('v-list-item-subtitle',[_vm._v(" principal amount ")])],1)],1),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"large":"","color":item.color}},[_vm._v(" mdi-chart-line ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" 12% ")]),_c('v-list-item-subtitle',[_vm._v(" interest ")])],1)],1),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"large":"","color":item.color}},[_vm._v(" mdi-cash-refund ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" 289000 ")]),_c('v-list-item-subtitle',[_vm._v(" repayment ")])],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-dialog',{attrs:{"width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("reports "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)]}}],null,true)},[_c('v-card',{attrs:{"width":"500"}},[_c('v-card-title',[_c('p',{staticClass:"text-uppercase mx-auto"},[_vm._v(" reports for "+_vm._s(item.title)+" ")])]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-3"},[_c('v-list',_vm._l((5),function(item){return _c('v-list-item',{key:item},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-circle-small ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(),"Do MMMM YYYY"))+" ")]),_c('v-list-item-subtitle',{staticClass:"error--text"},[_vm._v(" - KES 200,000 ")])],1)],1)}),1)],1)],1)],1)],1)],1)],1)],1)}),1)],1),_c('div',{staticClass:"text-h5 text-capitalize mt-2"},[_vm._v("recent loans")]),_c('v-card-title',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From Date","prepend-icon":"mdi-calendar","outlined":"","readonly":""},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromMenu),callback:function ($$v) {_vm.fromMenu=$$v},expression:"fromMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.fromMenu = false}},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To Date","outlined":"","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toMenu),callback:function ($$v) {_vm.toMenu=$$v},expression:"toMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.toMenu = false}},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1)],1),_c('v-card-title',[_c('v-icon',[_vm._v("mdi-text-search")]),_c('div',{staticClass:"text-uppercase"},[_vm._v("Search Results")]),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","outlined":"","label":"Search","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card',{attrs:{"color":"transparent","flat":""}},[_c('v-row',[_c('v-col',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" apply loan")],1)]}}])},[_c('v-card',{staticClass:"mx-auto",attrs:{"width":"500"}},[_c('v-card-title',[_c('div',{staticClass:"mx-auto text-uppercase font-weight-light"},[_vm._v(" apply loan ")])]),_c('v-card-text',[_c('v-form',[_vm._l((['Amount', 'Period', 'Maturity Date']),function(item){return _c('v-text-field',{key:item,attrs:{"label":item,"outlined":"","dense":""}})}),_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"primary"}},[_vm._v("cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"}},[_vm._v("apply")])],1)],2)],1)],1)],1),_c('v-data-table',{staticClass:"text-capitalize elevation-10",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"no-data-text":"No results for the current date black--text"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }