<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">loans</div>
      </v-row>
      <v-row class="">
        <div class="">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-5">
      <div>
        <v-row>
          <v-col v-for="item in overview" :key="item.title">
            <v-card>
              <v-card-title>
                <div class="font-weight-light text-capitalize">
                  {{ item.title }}
                </div>
              </v-card-title>
              <v-card-text>
                <v-list two-line class="text-capitalize">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon large :color="item.color"> mdi-cash </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> 389000 </v-list-item-title>
                      <v-list-item-subtitle>
                        principal amount
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon large :color="item.color">
                        mdi-chart-line
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> 12% </v-list-item-title>
                      <v-list-item-subtitle> interest </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon large :color="item.color">
                        mdi-cash-refund
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> 289000 </v-list-item-title>
                      <v-list-item-subtitle> repayment </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <div class="d-flex">
                  <v-spacer></v-spacer>
                  <v-dialog width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-on="on" v-bind="attrs"
                        >reports <v-icon right>mdi-chevron-right</v-icon></v-btn
                      >
                    </template>

                    <v-card width="500">
                      <v-card-title>
                        <p class="text-uppercase mx-auto">
                          reports for {{ item.title }}
                        </p>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text class="mt-3">
                        <v-list>
                          <v-list-item v-for="item in 5" :key="item">
                            <v-list-item-icon>
                              <v-icon> mdi-circle-small </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ new Date() | moment("Do MMMM YYYY") }}
                              </v-list-item-title>
                              <v-list-item-subtitle class="error--text">
                                - KES 200,000
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div class="text-h5 text-capitalize mt-2">recent loans</div>
      <v-card-title>
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="From Date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="fromMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="To Date"
              outlined
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="to" @input="toMenu = false"></v-date-picker>
        </v-menu>
      </v-card-title>

      <v-card-title>
        <v-icon>mdi-text-search</v-icon>
        <div class="text-uppercase">Search Results</div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          label="Search"
          dense
          v-model="search"
        ></v-text-field>
      </v-card-title>

      <v-card color="transparent" flat>
        <v-row>
          <v-col>
            <v-dialog width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" class="mb-2">
                  <v-icon left>mdi-plus</v-icon> apply loan</v-btn
                >
              </template>

              <v-card width="500" class="mx-auto">
                <v-card-title>
                  <div class="mx-auto text-uppercase font-weight-light">
                    apply loan
                  </div>
                </v-card-title>

                <v-card-text>
                  <v-form>
                    <v-text-field
                      v-for="item in ['Amount', 'Period', 'Maturity Date']"
                      :key="item"
                      :label="item"
                      outlined
                      dense
                    ></v-text-field>

                    <div class="d-flex">
                      <v-btn color="primary">cancel</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="primary">apply</v-btn>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              class="text-capitalize elevation-10"
              no-data-text="No results for the current date black--text"
            ></v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toMenu: false,
      fromMenu: false,
      from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      stepper: "1",
      headers: [
        {
          text: "recent loans",
          value: "date",
          class: "text-uppercase black--text",
        },
        {
          text: "type",
          value: "type",
          class: "text-uppercase black--text",
        },
        {
          text: "status",
          value: "status",
          class: "text-uppercase black--text",
        },
        {
          text: "reason",
          value: "reason",
          class: "text-uppercase black--text",
        },
      ],
      items: [
        {
          date: "Dec 10, 2021 7:30 AM",
          type: "mortgage",
          status: "approved",
          reason: "lorem ipsum",
        },
        {
          date: "Dec 12, 2021 4:30 AM",
          type: "mortgage",
          status: "approved",
          reason: "lorem ipsum",
        },
        {
          date: "Dec 13, 2021 10:30 AM",
          type: "mortgage",
          status: "approved",
          reason: "lorem ipsum",
        },
        {
          date: "Dec 14, 2021 9:00 AM",
          type: "mortgage",
          status: "approved",
          reason: "lorem ipsum",
        },
        {
          date: "Dec 16, 2021 11:30 AM",
          type: "mortgage",
          status: "approved",
          reason: "lorem ipsum",
        },
        {
          date: "Dec 18, 2021 9:30 AM",
          type: "mortgage",
          status: "approved",
          reason: "lorem ipsum",
        },
        {
          date: "Dec 20, 2021 10:30 AM",
          type: "mortgage",
          status: "approved",
          reason: "lorem ipsum",
        },
      ],
      overview: [
        {
          title: "salary loan",
          color: "primary",
        },
        {
          title: "mortgage loan",
          color: "green",
        },
        {
          title: "car loan",
          color: "purple",
        },
      ],
    };
  },
};
</script>

<style></style>
